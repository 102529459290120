












































































import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import { Form as ElForm, Input } from "element-ui";
import { isValidUsername } from "@/utils/validate";
import * as typ from "@/public/base_types";
import password from "@/components/password/password.vue";

@Component({
  components: { password },
  name: "TrainLogin",
})
export default class extends Vue {
  private loading: boolean = false;
  private redirect?: string;
  private otherQuery: Dictionary<string> = {};
  @Watch("$route", { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }

  private msg: string = "";

  private logoUrl(): string {
    return `${window.location.protocol}//${window.location.host}/assets/custom/images/logo.png`;
  }

  private defaultLogoUrl(): string {
    return require(`@/assets/images/logo.png`);
  }

  private validateUsername = (rule: any, value: string, callback: Function) => {
    if (!/^[a-zA-Z0-9_-]{4,16}$/.test(value)) {
      callback(new Error("用户名不符合规则"));
    } else {
      callback();
    }
    /*
    if (!isValidUsername(value)) {
      callback(new Error("Please enter the correct user name"));
    } else {
      callback();
    }
    */
  };

  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < typ.PSD_MIN_LEN) {
      callback(new Error(`密码长度不能少于${typ.PSD_MIN_LEN}个字符`));
    } else if (value.length > typ.PSD_MAX_LEN) {
      callback(new Error(`密码长度不能超过${typ.PSD_MAX_LEN}个字符`));
    } else if (
      !/^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/.test(
        value
      )
    ) {
      callback(
        new Error(
          "密码不符合安全规则,需包含大小写字母、数字及特殊符号：!@#$%^&*?"
        )
      );
    } else {
      callback();
    }
  };

  private loginForm = {
    username: "",
    password: "", // '123456'
  };

  private loginRules = {
    username: [
      {
        required: true,
        message: "请输入用户名",
        trigger: "blur",
      },
      { validator: this.validateUsername, trigger: "blur" },
    ],
    password: [
      {
        required: true,
        message: "请输入密码",
        trigger: "blur",
      },
      { validator: this.validatePassword, trigger: "blur" },
    ],
  };

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== "redirect") {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }
  private async login() {
    try {
      this.loading = true;
      this.msg = "";
      //
      await (this.$refs.loginForm as ElForm).validate();
      await UserModule.Login({
        username: this.loginForm.username,
        password: this.loginForm.password,
      });
      this.$router
        .push({
          path: this.redirect || "/",
          query: this.otherQuery,
        })
        .catch(() => {});
    } catch (error) {
      console.error(JSON.stringify(error));
      this.msg = error?.message || error?.msg || "登陆失败";
    } finally {
      this.loading = false;
    }
  }
  private doChangePasswordClick() {
    AppModule.UpdatePassWord(true);
  }
}
